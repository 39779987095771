.navbar-bground {
  background-image: url('../../../public/20190731_132644.jpg');
  background-size: contain;
  background-position: center;
  /* background-repeat: no-repeat;     */
  height: 78.5vh; 
  width: 100%;
  align-items: start;
  margin: 0;
}

.navbar .container-fluid {
  background-color: hsla(0, 0%, 90%, 1);
  padding: 0.5rem;
}

.navbar-bground a:hover {
  color: #2f3f92;
}

.navbar {
  --bs-navbar-active-color: #2f3f92 !important;
}

.me-2:focus {
  text-shadow: none;
  border: unset;
}

.navbar-bground button{
  background-color: #2f3f92;
  border: none;
}

@media (max-width: 961px) {
  .navbar-bground {
    background-size: cover;
    background-repeat: no-repeat;    
  }
}
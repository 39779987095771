.aboutus-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
}

.aboutus-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
}

.aboutus-container img {
  transition: all 0.2s ease-in-out;
  width: 100px;
}
.aboutus-container img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.aboutus-container a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: inherit;
}

.aboutus-container h5 {
  transition: all 0.2s ease-in-out;
}

.aboutus-container h5:hover {
  color: #2f3f92;
  cursor: pointer;
  transform: scale(1.1);
}
.footer {
  color: white;
  padding: 2rem;
  display: flex;
  justify-content: space-around;
  background-color: #2f3f92;
}


.footer ul li {
  list-style-type: none;
  padding: 2px 0;
}

.footer ul {
  padding: 0;
}

.footer-social-icons {
  display: flex;
  gap: 1rem;
}

.footer-social-icons img {
  transition: all 0.2s ease-in-out;
  width: 30px;
  height: 30px;
}
.footer-social-icons img:hover {
  transform: scale(1.1);
}

.footer-about-us-header {
  border-bottom: 1px solid rgb(132, 132, 132, 0.5);
  padding-bottom: 5px;
}

.footer-about-us {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 4rem;
}
 .footer-about-us-a {
  display: flex;
  flex-direction: column;
}
.footer-about-us a {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-in-out;
  /* display: inline-block; */
}
.footer-about-us a:hover {
  color: #00FFFF;
  /* transform: scale(1.1); */
}

.footer-social {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 650px) {
  .footer {
    flex-direction: column;
    gap: 2rem;
  }
}